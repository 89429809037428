import { useQuery } from "@tanstack/react-query";

import { fetchApiSingle } from "../fetchers";
import { type IApiQueryParams } from "../types";

export interface UseApiQueryOptions {
  enabled?: boolean;
  retry?: boolean | number;
}

const useApiQuery = <RecordType = unknown>(
  url: string,
  queryParams: IApiQueryParams = {},
  options: UseApiQueryOptions = {},
) => {
  const query = useQuery(
    [url, queryParams],
    async () => await fetchApiSingle<RecordType>(url, queryParams),
    { enabled: options.enabled, retry: options.retry },
  );

  return { ...query, record: query.data?.record };
};

export default useApiQuery;
