import type { ReactNode } from "react";
import { useState } from "react";

import type { LocationState } from "./LocationContext";
import { useAsyncStorageSyncronizer } from "~root/services/async-storage";
import { LocationContext } from "./LocationContext";

export interface LocationProviderProps {
  children: ReactNode;
}

export default function LocationProvider({ children }: LocationProviderProps) {
  const [state, setState] = useState<LocationState>();

  useAsyncStorageSyncronizer<LocationState | undefined>({
    value: state,
    initialValue: undefined,
    key: "location",
    isJson: true,
    onLoaded: setState,
    compare: (a, b) => a?.googlePlaceId === b?.googlePlaceId,
  });

  return (
    <LocationContext.Provider
      value={{
        state,
        setState,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
}
