import { useMemo } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

import { type IGetManyResponse } from "@cannabox/api-types";

import { fetchApiList } from "../fetchers";
import { type IApiQueryParams } from "../types";

const useRecordsFromPages = <RecordType = unknown>(
  pages?: Array<IGetManyResponse<RecordType>>,
) =>
  useMemo(() => {
    const recordsResult: RecordType[] = [];

    for (const page of pages ?? []) {
      recordsResult.push(...page.records);
    }

    return recordsResult;
  }, [pages]);

export interface IUseInfiniteApiQueryOptions {
  enabled?: boolean;
}

const useInfiniteApiQuery = <RecordType = unknown>(
  url: string,
  queryParams: IApiQueryParams = {},
  options: IUseInfiniteApiQueryOptions = {},
) => {
  type ResponseType = IGetManyResponse<RecordType>;

  const queryResults = useInfiniteQuery<ResponseType>(
    [url, queryParams],
    async ({ pageParam = 1 }) =>
      await fetchApiList(url, { page: pageParam as number, ...queryParams }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.currentPage < lastPage.totalPages
          ? lastPage.currentPage + 1
          : undefined,
      ...options,
    },
  );

  const records = useRecordsFromPages(queryResults.data?.pages);

  return { ...queryResults, records };
};

export default useInfiniteApiQuery;
