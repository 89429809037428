import type {
  GetGooglePlaceCordsRecord,
  GetGooglePlaceCordsUrlParams,
} from "@cannabox/api-types";

import { fetchApiSingle } from "~root/services/api/fetchers";

const API_PATH = "/maps/google-place-cords";

export const fetchGooglePlaceCords = async (
  params: GetGooglePlaceCordsUrlParams,
) => {
  return await fetchApiSingle<GetGooglePlaceCordsRecord>(
    `${API_PATH}/${params.googlePlaceId}`,
  );
};
