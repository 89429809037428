import { useState } from "react";
import { IconButton } from "native-base";

import CannaboxEyesIcon from "~root/components/icons/CannaboxEyesIcon";
import RegisterIcon from "~root/components/icons/RegisterIcon";
import UserPanel from "~root/components/user-panel/UserPanel";
import {
  useIsAuthenticationReady,
  useIsLoggedIn,
  useLoggedInUser,
} from "~root/services/auth/hooks";
import { useRootNavigation } from "../hooks";

const ENABLE_USER_PROFILE = false;

export default function HeaderRightIcon() {
  const [isUserPanelOpen, setIsUserPanelOpen] = useState(false);
  const navigation = useRootNavigation();

  const isAuthReady = useIsAuthenticationReady();
  const isLoggedIn = useIsLoggedIn();
  const user = useLoggedInUser();

  if (!ENABLE_USER_PROFILE) return null;
  if (!isAuthReady) return null;

  if (isLoggedIn)
    return (
      <>
        <UserPanel
          isUserPanelOpen={isUserPanelOpen}
          setIsUserPanelOpen={setIsUserPanelOpen}
          user={user}
        />
        <IconButton
          margin="4"
          padding="1.5"
          borderRadius="full"
          bg="purple.300"
          icon={<CannaboxEyesIcon />}
          onPress={() => {
            setIsUserPanelOpen(true);
          }}
        />
      </>
    );

  return (
    <IconButton
      padding="4"
      icon={<RegisterIcon />}
      onPress={() => {
        navigation.navigate("Register");
      }}
    />
  );
}
