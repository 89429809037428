import { extendTheme } from "native-base";

import * as colors from "./colors";
import * as components from "./components";
import * as shadows from "./shadows";

export const cannaboxTheme = extendTheme({
  colors: { ...colors },
  shadows: { ...shadows },
  components: { ...components },
});

export type CannaboxTheme = typeof cannaboxTheme;
