import { useQuery } from "@tanstack/react-query";

import type { AutoCompleteAddressRecord } from "@cannabox/api-types";

import { fetchApiList } from "~root/services/api/fetchers";

const API_PATH = "/maps/autocomplete-address";

export const useAutocompleteAddressQuery = (query: string) => {
  return useQuery(
    [API_PATH, query],
    async () =>
      await fetchApiList<AutoCompleteAddressRecord>(API_PATH, { query }),
    {
      enabled: !!query,
    },
  );
};
