import { useMutation } from "@tanstack/react-query";

import {
  type IClaimStoreBodyParams,
  type IClaimStoreUrlParams,
} from "@cannabox/api-types";

import { fetchApi } from "../fetchers";

export type IClaimStoreMutationOptions = IClaimStoreUrlParams &
  IClaimStoreBodyParams;

const useClaimStoreMutation = () => {
  return useMutation(
    async ({
      city,
      urlSlug,
      name,
      email,
      phone,
    }: IClaimStoreMutationOptions) => {
      return await fetchApi({
        url: `/stores/${city}/${urlSlug}/claim`,
        method: "POST",
        body: { name, email, phone },
      });
    },
  );
};

export default useClaimStoreMutation;
