import { type ReactNode } from "react";
import { NativeBaseProvider } from "native-base";

import { cannaboxTheme } from "./CannaboxTheme";

export interface CannaboxThemeProviderProps {
  children: ReactNode;
}

export default function CannaboxThemeProvider({
  children,
}: CannaboxThemeProviderProps) {
  return (
    <NativeBaseProvider theme={cannaboxTheme}>{children}</NativeBaseProvider>
  );
}
