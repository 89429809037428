import type { StoreInventoryRecordCategoryType } from "@cannabox/api-types";

export const supportedProductCategories: Record<
  StoreInventoryRecordCategoryType,
  string
> = {
  flowers: "Flowers",
  edibles: "Edibles",
  prerolls: "Prerolls",
  tinctures: "Tinctures",
};
