import { createContext } from "react";

import type { IMyUserRecord } from "@cannabox/api-types";

import type { AuthActionDispathcer, AuthState } from "./state";

export type IAuthContext = AuthState & {
  isReady: boolean;
  user?: IMyUserRecord;
  refetchUserCallback?: () => void;
  dispatch?: AuthActionDispathcer;
};

export default createContext<IAuthContext>({ isReady: false });
