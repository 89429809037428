import { cannaboxEnv } from "./env";

const getDomain = () => {
  switch (cannaboxEnv) {
    case "production":
      return "cannabox.co.th";
    case "development":
      return "development.cannabox.co.th";
    case "local":
      return "localhost";
  }
};

const hostname = window.location.hostname;
const domain = getDomain();

export const isStandAloneStore = hostname !== domain;
export const [standAloneStoreUrlSlug, standAloneStoreCity] = isStandAloneStore
  ? hostname.split(".")
  : ["", ""];
