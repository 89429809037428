import { useCallback, useContext } from "react";

import type {
  EmailResetPasswordBodyParams,
  ILoginBodyParams,
  IRegisterBodyParams,
  ResetPasswordBodyParams,
} from "@cannabox/api-types";

import {
  useEmailResetPasswordMutation,
  useLoginMutation,
  useRegisterMutation,
  useResetPasswordMutation,
} from "~root/services/api";
import AuthContext from "./AuthContext";

const useAuthContext = () => useContext(AuthContext);

export const useAccessToken = () => useAuthContext().accessToken;

export const useLoggedInUser = () => useAuthContext().user;

export const useRefetchUserCallback = () =>
  useAuthContext().refetchUserCallback;

export const useIsAuthenticationReady = () => useAuthContext().isReady;

export const useIsLoggedIn = () => useLoggedInUser() !== undefined;

export const useLogoutCallback = () => {
  const { dispatch } = useAuthContext();
  const isLoggedIn = useIsLoggedIn();

  return useCallback(() => {
    if (!isLoggedIn) return;

    dispatch?.({ type: "LOGOUT" });
  }, [dispatch, isLoggedIn]);
};

export const useLoginCallback = () => {
  const { dispatch } = useAuthContext();
  const isLoggedIn = useIsLoggedIn();

  const loginMutation = useLoginMutation({
    onSuccess: ({ record }) => {
      dispatch?.({ type: "SET_ACCESS_TOKEN", payload: record });
    },
  });

  const login = useCallback(
    (params: ILoginBodyParams) => {
      if (!dispatch) return;
      if (isLoggedIn) return;

      loginMutation.mutate(params);
    },
    [dispatch, isLoggedIn, loginMutation],
  );

  return {
    login,
    ...loginMutation,
  };
};

export const useRegisterCallback = () => {
  const { dispatch } = useAuthContext();
  const isLoggedIn = useIsLoggedIn();

  const registerMutation = useRegisterMutation({
    onSuccess: ({ record }) => {
      dispatch?.({ type: "SET_ACCESS_TOKEN", payload: record });
    },
  });

  const register = useCallback(
    (params: IRegisterBodyParams) => {
      if (!dispatch) return;
      if (isLoggedIn) return;

      registerMutation.mutate(params);
    },
    [dispatch, isLoggedIn, registerMutation],
  );

  return {
    register,
    ...registerMutation,
  };
};

export const useEmailResetPasswordCallback = (
  onSuccess: (email: string) => void,
  email: string,
) => {
  const emailResetPasswordMutation = useEmailResetPasswordMutation({
    onSuccess: () => {
      onSuccess(email);
    },
  });

  const emailResetPassword = useCallback(
    (params: EmailResetPasswordBodyParams) => {
      emailResetPasswordMutation.mutate(params);
    },
    [emailResetPasswordMutation],
  );

  return { emailResetPassword, ...emailResetPasswordMutation };
};

export const useResetPasswordCallBack = (onSuccess: () => void) => {
  const { dispatch } = useAuthContext();

  const resetPasswordMutation = useResetPasswordMutation({
    onSuccess: ({ record }) => {
      dispatch?.({ type: "SET_ACCESS_TOKEN", payload: record });
      onSuccess();
    },
  });

  const resetPassword = useCallback(
    (params: ResetPasswordBodyParams) => {
      if (!dispatch) return;

      resetPasswordMutation.mutate(params);
    },
    [dispatch, resetPasswordMutation],
  );

  return { resetPassword, ...resetPasswordMutation };
};
