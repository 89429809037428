import { cannaboxEnv } from "./env";

export const supportedCities: Record<string, string> = {
  bangkok: "Bangkok",
  phuket: "Phuket",
  pattaya: "Pattaya",
  "chiang-mai": "Chiang Mai",
  "hua-hin": "Hua Hin",
  "ko-samui": "Ko Samui",
  "ko-phangan": "Ko Phangan",
  "khon-kaen": "Khon Kaen",
};

if (cannaboxEnv !== "production") {
  supportedCities.gotham = "Gotham";
}
