import { Path } from "react-native-svg";
import { createIcon } from "native-base";

export default createIcon({
  viewBox: "0 0 20 20",
  path: [
    <Path
      key="1"
      fill="#ffffff"
      d="M8 8.84766C10.127 8.84766 11.8496 6.9668 11.8496 4.66406C11.8496 2.39648 10.127 0.594727 8 0.594727C5.88184 0.594727 4.1416 2.42285 4.15039 4.68164C4.15918 6.97559 5.87305 8.84766 8 8.84766ZM8 7.30957C6.78711 7.30957 5.75879 6.1582 5.75879 4.68164C5.75 3.24023 6.77832 2.13281 8 2.13281C9.23047 2.13281 10.2412 3.22266 10.2412 4.66406C10.2412 6.14062 9.22168 7.30957 8 7.30957ZM2.51562 17.0215H13.4756C14.9961 17.0215 15.7256 16.5381 15.7256 15.501C15.7256 13.084 12.7109 9.86719 8 9.86719C3.28906 9.86719 0.265625 13.084 0.265625 15.501C0.265625 16.5381 0.995117 17.0215 2.51562 17.0215ZM2.24316 15.4834C2.03223 15.4834 1.95312 15.4131 1.95312 15.2549C1.95312 13.9102 4.12402 11.4053 8 11.4053C11.8672 11.4053 14.0381 13.9102 14.0381 15.2549C14.0381 15.4131 13.959 15.4834 13.748 15.4834H2.24316Z"
    />,
  ],
});
