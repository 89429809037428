import { Platform } from "react-native";

let referrer: string | null = null;

if (Platform.OS === "web") {
  const url = new URL(window.location.href);

  referrer = url.searchParams.get("referrer");
}

export default referrer;
