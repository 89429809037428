import type { Dispatch } from "react";
import { useMemo } from "react";

import type { CartAction, CartState } from "./state";
import { useAsyncStorageSyncronizer } from "~root/services/async-storage";
import { hoursToMilliseconds } from "../dates/utils";
import { initialCartState } from "./CartContext";

const CART_EXPIRATION_MILISECONDS = hoursToMilliseconds(2);

type CartInStorage = Omit<CartState, "updatedAt"> & {
  updatedAt: string;
};

const convertCartStateToCartInStorage = (
  cartState: CartState,
): CartInStorage => {
  const updatedAt = cartState.updatedAt ?? new Date();

  return {
    ...cartState,
    updatedAt: updatedAt.toISOString(),
  };
};

const convertCartInStorageToCartState = (
  cartInStorage: CartInStorage,
): CartState => {
  const updatedAt = new Date(cartInStorage.updatedAt);

  return {
    ...cartInStorage,
    updatedAt,
  };
};

const initialCartInStorage = convertCartStateToCartInStorage(initialCartState);

/**
 * Synchronize cart state with async storage
 *
 * @param state Cart state
 * @param dispatch Cart state dispatch
 * @returns Whether cart is being loaded from async storage
 **/
export const useCartAsyncStorageSynchronizer = (
  state: CartState,
  dispatch: Dispatch<CartAction>,
) => {
  const { isLoadingStoredValue } = useAsyncStorageSyncronizer<CartInStorage>({
    key: "@cannabox-cart",
    isJson: true,
    initialValue: initialCartInStorage,
    value: useMemo(() => convertCartStateToCartInStorage(state), [state]),
    compare: (a, b) => a.updatedAt === b.updatedAt,
    onLoaded: (initialCartInStorage) => {
      const now = new Date();
      const updatedAt = new Date(initialCartInStorage.updatedAt);
      const expirationTime = +updatedAt + CART_EXPIRATION_MILISECONDS;

      if (+now < expirationTime) {
        dispatch({
          type: "SET_CART",
          payload: convertCartInStorageToCartState(initialCartInStorage),
        });
      }
    },
  });

  return isLoadingStoredValue;
};
