import { createContext } from "react";

import type { CartActionDispatcher, CartState } from "./state/types";

export type ICartContext = CartState & {
  isLoading: boolean;
  dispatch?: CartActionDispatcher;
};

export const initialCartState: CartState = {
  store: undefined,
  items: undefined,
} as const;

export const CartContext = createContext<ICartContext>({
  ...initialCartState,
  isLoading: true,
});
