import { useCallback, useMemo } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";

import type {
  GenericRouteProp,
  ProductGalleryRouteProp,
  ProductRouteProp,
  ResetPasswordRouteProp,
  RootStackNavigationProps,
  RootStackParamList,
  StoreGalleryRouteProp,
  StoreReviewGalleryRouteProp,
  StoreRouteProp,
  StoresRouteProp,
  VerifyEmailAddressRouteProp,
} from "./types";

export const useRootNavigation = () => {
  return useNavigation<RootStackNavigationProps>();
};

export const useStoresRoute = () => useRoute<StoresRouteProp>();
export const useStoreRoute = () => useRoute<StoreRouteProp>();
export const useStoreGalleryRoute = () => useRoute<StoreGalleryRouteProp>();
export const useStoreReviewGalleryRoute = () =>
  useRoute<StoreReviewGalleryRouteProp>();

export const useProductRoute = () => useRoute<ProductRouteProp>();
export const useProductGalleryRoute = () => useRoute<ProductGalleryRouteProp>();

export const useVerifyEmailAddressRoute = () =>
  useRoute<VerifyEmailAddressRouteProp>();

export const useResetPasswordRoute = () => useRoute<ResetPasswordRouteProp>();

export const useGoBackToScreenCallback = <
  RouteName extends keyof RootStackParamList,
>(
  args?: GenericRouteProp<RouteName>,
) => {
  const [screen, params] = args ?? [];
  const navigation = useRootNavigation();

  const canGoBack = useMemo(
    () =>
      screen
        ? navigation.getState().routes.slice(1, -1)[0]?.name === screen
        : true,
    [navigation, screen],
  );

  return useCallback(() => {
    if (!args || canGoBack) {
      navigation.goBack();
    } else {
      navigation.replace<RouteName>(...args);
    }
  }, [canGoBack, navigation, screen, params]);
};
