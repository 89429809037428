import { useQuery } from "@tanstack/react-query";

import type {
  GetRecommendedProductsQueryParams,
  GetRecommendedProductsRecord,
  IGetManyResponse,
} from "@cannabox/api-types";

import { fetchApiList } from "../fetchers";

const API_PATH = "/recommendations/products";

export interface UseRecommendedProductsQueryOptions
  extends Omit<GetRecommendedProductsQueryParams, "location"> {
  location?: { latitude: number; longitude: number };
}

export const useRecommendedProductsQuery = ({
  city,
  storeUrlSlug,
  location,
}: UseRecommendedProductsQueryOptions) => {
  const params: Record<string, string> = {};

  if (city) params.city = city;
  if (storeUrlSlug) params.storeUrlSlug = storeUrlSlug;
  if (location) params.location = `${location.longitude},${location.latitude}`;

  return useQuery<IGetManyResponse<GetRecommendedProductsRecord>>(
    ["recommended-products", params],
    async () =>
      await fetchApiList<GetRecommendedProductsRecord>(API_PATH, params),
  );
};
