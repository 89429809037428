import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

import type {
  EmailResetPasswordBodyParams,
  EmailResetPasswordSuccessResponse,
} from "@cannabox/api-types";

import type { ApiError } from "../errors";
import { fetchApi } from "../fetchers";

export type EmailResetPasswordMutationOptions = UseMutationOptions<
  EmailResetPasswordSuccessResponse,
  ApiError,
  EmailResetPasswordBodyParams
>;

const mutation = async (body: EmailResetPasswordBodyParams) =>
  await fetchApi<
    EmailResetPasswordSuccessResponse,
    EmailResetPasswordBodyParams
  >({
    url: "/auth/email-reset-password",
    method: "POST",
    body,
  });

export const useEmailResetPasswordMutation = (
  options: EmailResetPasswordMutationOptions = {},
) =>
  useMutation<
    EmailResetPasswordSuccessResponse,
    ApiError,
    EmailResetPasswordBodyParams
  >(mutation, {
    ...options,
    mutationKey: ["email-reset-password"],
  });
