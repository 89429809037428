import type {
  IGetManyStoresQueryParams,
  IGetManyStoresRecord,
  IGetManyStoresUrlParams,
} from "@cannabox/api-types";

import useInfiniteApiQuery from "../hooks/useInfiniteApiQuery";

export interface IUseStoresQueryOptions
  extends IGetManyStoresUrlParams,
    Omit<IGetManyStoresQueryParams, "location"> {
  location?: { latitude: number; longitude: number };
  enabled?: boolean;
}

const useInfiniteStoresQuery = ({
  location,
  city,
  enabled = true,
}: IUseStoresQueryOptions) => {
  const query: Record<string, string> = {};

  if (location) {
    query.location = `${location.longitude},${location.latitude}`;
  }

  if (city) {
    query.city = city;
  }

  return useInfiniteApiQuery<IGetManyStoresRecord>("/stores", query, {
    enabled,
  });
};

export default useInfiniteStoresQuery;
