import type { ReactNode } from "react";
import { useEffect, useState } from "react";

import type { IFirebaseContext } from "./FirebaseContext";
import { firebaseConfig } from "../../config";
import FirebaseContext from "./FirebaseContext";
import referrer from "./referrer";

export interface IFirebaseProviderProps {
  children: ReactNode;
}

export default function FirebaseProvider({ children }: IFirebaseProviderProps) {
  const [context, setContext] = useState<IFirebaseContext>({});

  useEffect(() => {
    const loadFirebase = async () => {
      if (!firebaseConfig) return;

      const { initializeApp } = await import(
        /* webpackChunkName: "firebase-app" */ "firebase/app"
      );
      const { getAnalytics, setUserProperties } = await import(
        /* webpackChunkName: "firebase-analytics" */ "firebase/analytics"
      );

      const firebase = initializeApp(firebaseConfig);
      const analytics = getAnalytics(firebase);

      if (referrer) {
        setUserProperties(analytics, {
          referrer,
        });
      }

      setContext({ firebase, analytics });
    };

    loadFirebase().catch(console.error);
  }, [setContext]);

  // const context = useMemo<IFirebaseContext>(() => {
  //   if (!firebaseConfig) {
  //     return {};
  //   }

  //   const firebase = initializeApp(firebaseConfig);
  //   const analytics = getAnalytics(firebase);

  //   if (referrer) {
  //     setUserProperties(analytics, {
  //       referrer,
  //     });
  //   }

  //   return { firebase, analytics };
  // }, []);

  return (
    <FirebaseContext.Provider value={context}>
      {children}
    </FirebaseContext.Provider>
  );
}
