import loadable from "@loadable/component";

export const ProductGalleryView = loadable(
  async () =>
    await import(
      /* webpackChunkName: "ProductGallery" */ "./ProductGalleryView"
    ),
);

export const ProductView = loadable(
  async () => await import(/* webpackChunkName: "Product" */ "./ProductView"),
);
