import loadable from "@loadable/component";

export const StoresListView = loadable(
  async () => await import(/* webpackChunkName: "Stores" */ "./StoresListView"),
);
export const StoreView = loadable(
  async () => await import(/* webpackChunkName: "Store" */ "./StoreView"),
);

export * from "./gallery";
