import { type IButtonProps } from "native-base";

const solidVariant = ({ colorScheme }: IButtonProps): IButtonProps => {
  switch (colorScheme) {
    case "primary":
    case "secondary":
    case "green":
    case "red":
    case "yellow":
    case "orange":
    case "teal":
    case "cyan":
    case "blue":
    case "purple":
    case "pink":
    case "rose":
    case "emerald":
    case "lime":
    case "amber":
    case "blueGray":
    case "warmGray":
    case "trueGray":
    case "coolGray":
    case "gray":
      return {
        bg: `${colorScheme}.300`,
        _text: {
          color: "darkText",
        },
        _pressed: {
          bg: `${colorScheme}.500`,
        },
        _icon: {
          color: "darkText",
        },
      };
    case "light":
      return {
        bg: "gray.100",
        _text: {
          color: "darkText",
        },
        _icon: {
          color: "darkText",
        },
        _pressed: {
          bg: "gray.200",
        },
      };
    case "white":
      return {
        bg: "white",
        _text: {
          color: "darkText",
        },
        _icon: {
          color: "darkText",
        },
        _pressed: {
          bg: "gray.200",
        },
        _disabled: {
          color: "muted.400",
          _icon: {
            color: "muted.400",
          },
        },
      };
    case "dark":
    case "black":
    default:
      return {
        bg: "gray.900",
        _text: {
          color: "lightText",
        },
        _icon: {
          color: "lightText",
        },
        _pressed: {
          bg: "gray.700",
        },
      };
  }
};

const outlineVariant = (): IButtonProps => {
  return {
    borderColor: `gray.200`,
    borderTopRadius: 24,
    borderBottomRadius: 24,
  };
};

const linkVariant = ({ colorScheme }: IButtonProps): IButtonProps => {
  switch (colorScheme) {
    case "white":
      return {
        _text: {
          color: "white",
          fontWeight: "medium",
        },
        _pressed: {
          _text: {
            color: "gray.400",
            textDecoration: "none",
          },
        },
      };
    case "muted":
      return {
        _text: {
          color: "muted.400",
        },
      };
    default:
      return {};
  }
};

const subtleVariant = ({ colorScheme }: IButtonProps): IButtonProps => {
  switch (colorScheme) {
    case "white":
      return {
        bg: "white",
        _text: {
          color: "darkText",
        },
        _pressed: {
          bg: "gray.200",
        },
      };
    case "light":
      return {
        bg: "gray.50",
        _text: {
          color: "darkText",
        },
        _pressed: {
          bg: "secondary.100",
        },
      };
    default:
      return {};
  }
};

export const Button = {
  defaultProps: {
    variant: "solid",
    borderRadius: "2xl",
  } satisfies IButtonProps,
  sizes: {
    lg: {
      py: 2.5,
      _text: {
        fontSize: "lg",
        fontWeight: "medium",
      },
    },
    md: {
      py: 3,
      _text: {
        fontSize: "md",
      },
    },
    sm: {
      py: 2,
      _text: {
        fontSize: "sm",
      },
    },
  },
  variants: {
    solid: solidVariant,
    outline: outlineVariant,
    link: linkVariant,
    subtle: subtleVariant,
  },
};

export const IconButton = {
  ...Button,
  defaultProps: {
    variant: "ghost",
  },
};
