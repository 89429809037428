import { createContext } from "react";
import { type Analytics } from "firebase/analytics";
import { type FirebaseApp } from "firebase/app";

export interface IFirebaseContext {
  firebase?: FirebaseApp;
  analytics?: Analytics;
}

export default createContext<IFirebaseContext>({});
