import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { isStandAloneStore } from "~root/config";
import {
  AddressPickerView,
  CartView,
  CheckoutView,
  LoginView,
  ProductGalleryView,
  ProductView,
  ProfileView,
  RegisterView,
  ResetPasswordView,
  SetNewPasswordView,
  StoreGalleryView,
  StoreReviewGalleryView,
  StoresListView,
  StoreView,
  VerifyEmailAddressView,
} from "~root/views";
import AddressHeaderTitle from "./components/AddressHeaderTitle";
import CloseIconButton from "./components/CloseIconButton";
import HeaderRightIcon from "./components/HeaderRightIcon";
import { type RootStackParamList } from "./types";

const StackNavigator = createNativeStackNavigator<RootStackParamList>();

export default function Navigation() {
  return (
    <StackNavigator.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: "#252525",
        },
        headerTintColor: "#fff",
      }}
    >
      <StackNavigator.Group
        screenOptions={{
          headerTitle: () => <AddressHeaderTitle />,
          headerTitleAlign: "left",
          headerRight: () => <HeaderRightIcon />,
        }}
      >
        {!isStandAloneStore && (
          <StackNavigator.Screen name="Stores" component={StoresListView} />
        )}
        <StackNavigator.Screen name="Store" component={StoreView} />
        <StackNavigator.Screen name="Product" component={ProductView} />
      </StackNavigator.Group>

      <StackNavigator.Group
        screenOptions={{
          presentation: "fullScreenModal",
          headerLeft: () => <CloseIconButton />,
        }}
      >
        <StackNavigator.Screen
          name="AddressPicker"
          component={AddressPickerView}
        />
      </StackNavigator.Group>

      <StackNavigator.Group
        screenOptions={{
          presentation: "fullScreenModal",
        }}
      >
        <StackNavigator.Screen
          name="Cart"
          component={CartView}
          options={{
            headerTitle: "Cart",
            headerLeft: () => <CloseIconButton />,
          }}
        />
        <StackNavigator.Screen
          name="Checkout"
          component={CheckoutView}
          options={{
            headerTitle: "Checkout",
          }}
        />
      </StackNavigator.Group>

      <StackNavigator.Group
        screenOptions={{
          presentation: "fullScreenModal",
          headerTitle: "",
          headerRight: undefined,
          headerLeft: () => <CloseIconButton color="black" />,
          headerTransparent: true,
        }}
      >
        <StackNavigator.Screen name="Register" component={RegisterView} />
        <StackNavigator.Screen name="Login" component={LoginView} />
        <StackNavigator.Screen
          name="ResetPassword"
          component={ResetPasswordView}
        />
        <StackNavigator.Screen
          name="SetNewPassword"
          component={SetNewPasswordView}
        />
        <StackNavigator.Screen
          name="VerifyEmailAddress"
          component={VerifyEmailAddressView}
          options={{
            headerShown: false,
          }}
        />
      </StackNavigator.Group>

      <StackNavigator.Group
        screenOptions={{
          presentation: "modal",
          headerTransparent: true,
          headerTitle: "",
          headerLeft: () => <CloseIconButton color="black" />,
        }}
      >
        <StackNavigator.Screen name="Profile" component={ProfileView} />
      </StackNavigator.Group>

      <StackNavigator.Group
        screenOptions={{
          presentation: "modal",
          headerTitle: "",
          headerTransparent: true,
          headerLeft: () => <CloseIconButton />,
          headerRight: undefined,
        }}
      >
        <StackNavigator.Screen
          name="StoreGallery"
          component={StoreGalleryView}
        />
        <StackNavigator.Screen
          name="StoreReviewGallery"
          component={StoreReviewGalleryView}
        />
        <StackNavigator.Screen
          name="ProductGallery"
          component={ProductGalleryView}
        />
      </StackNavigator.Group>
    </StackNavigator.Navigator>
  );
}
