import { Path } from "react-native-svg";
import { createIcon } from "native-base";

export default createIcon({
  viewBox: "0 0 17 17",
  path: [
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 6.99309C14 8.57271 13.2003 10.0139 12.3186 11.3252C10.8017 13.5813 9.68061 14.7921 7.82437 16.6576C7.37012 17.1141 6.62988 17.1141 6.17563 16.6576C4.31939 14.7921 3.19834 13.5813 1.68136 11.3252C0.79969 10.0139 0 8.57271 0 6.99309C0 3.13091 3.13401 0 7 0C10.866 0 14 3.13091 14 6.99309ZM7 9.32412C8.75 9.32412 9.33333 8.1586 9.33333 6.99309C9.33333 5.82757 8.75 4.66206 7 4.66206C5.25 4.66206 4.66667 5.82757 4.66667 6.99309C4.66667 8.1586 5.25 9.32412 7 9.32412Z"
    />,
  ],
});
