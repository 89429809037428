import type { FirebaseOptions } from "firebase/app";

import { isStandAloneStore } from "./standAloneStoreConfig";

export const firebaseConfig: FirebaseOptions | undefined =
  process.env.FIREBASE_API_KEY && !isStandAloneStore
    ? {
        apiKey: process.env.FIREBASE_API_KEY,
        authDomain: process.env.FIREBASE_AUTH_DOMAIN,
        projectId: process.env.FIREBASE_PROJECT_ID,
        storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.FIREBASE_APP_ID,
        measurementId: process.env.FIREBASE_MEASUREMENT_ID,
      }
    : undefined;
