export const footerActionsShadow = {
  shadowColor: "black",
  shadowOffset: {
    width: 6,
    height: 4,
  },
  shadowOpacity: 0.1,
  shadowRadius: 64,
  elevation: 9,
};

export const productCardShadow = {
  shadowColor: "#473b22",
  shadowOffset: {
    width: 10,
    height: 10,
  },
  shadowOpacity: 0.05,
  shadowRadius: 24,
  elevation: 9,
};
