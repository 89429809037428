export const red = {
  50: "#ff778f",
};

export const green = {
  50: "#e1ffe1",
  100: "#b5fcb4",
  200: "#88f886",
  300: "#5af557",
  400: "#2ff229",
  500: "#18d811",
  600: "#0ea80a",
  700: "#067805",
  800: "#004800",
  900: "#001900",
};

export const fadedGreen = {
  50: "#F7FFF7",
  100: "#D5FFD4",
  200: "#B3FFB1",
  300: "#A1EC9F",
  400: "#8CD28A",
  500: "#77B957",
  600: "#639F62",
  700: "#518650",
  800: "#3F6D3E",
  900: "#2F532E",
};

export const yellow = {
  50: "#fffaef",
  100: "#fffacc",
  200: "#fff6ab",
  300: "#fef18b",
  400: "#fcec73",
  500: "#fae75e",
  600: "#ffde61",
  700: "#ffcb5c",
  800: "#fab554",
  900: "#f19146",
};

export const purple = {
  50: "#ece6f7",
  100: "#cfc1eb",
  200: "#af98df",
  300: "#8e6ed2",
  400: "#754ec9",
  500: "#5a2ebf",
  600: "#502ab9",
  700: "#4122b1",
  800: "#311ca9",
  900: "#100f9b",
};

export const primary = fadedGreen;
export const secondary = yellow;
