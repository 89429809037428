import type { PathConfigMap } from "@react-navigation/native";

import type { RootStackParamList } from "./types";

export default function createNavigationConfig(isStandAloneStore: boolean) {
  const screens: PathConfigMap<RootStackParamList> = {
    ...(isStandAloneStore
      ? {
          Store: "/",
        }
      : {
          Stores: "/:city?",
          Store: "/:city/:urlSlug",
        }),

    StoreGallery: "/:city/:urlSlug/gallery/:imageIndex?",
    StoreReviewGallery: "/:city/:urlSlug/reviews/:imageIndex?",
    Product: "/:city/:urlSlug/:category/:productUrlSlug",
    ProductGallery:
      "/:city/:urlSlug/:category/:productUrlSlug/gallery/:imageIndex?",

    AddressPicker: "/address-picker",

    Cart: "/cart",
    Checkout: "/checkout",

    VerifyEmailAddress: "/auth/verify/:token",
    Register: "/auth/register",
    Login: "/auth/login",
    ResetPassword: "/auth/reset-password",
    SetNewPassword: "/auth/set-new-password/:token",
    Profile: "/profile",
  };

  const initialRouteName: keyof RootStackParamList = isStandAloneStore
    ? "Store"
    : "Stores";

  return {
    initialRouteName,
    screens,
  };
}
