import loadable from "@loadable/component";

export const VerifyEmailAddressView = loadable(
  async () =>
    await import(
      /* webpackChunkName: "VerifyEmailAddress" */ "./VerifyEmailAddressView"
    ),
);

export const RegisterView = loadable(
  async () => await import(/* webpackChunkName: "Register" */ "./RegisterView"),
);

export const LoginView = loadable(
  async () => await import(/* webpackChunkName: "Login" */ "./LoginView"),
);

export const ResetPasswordView = loadable(
  async () =>
    await import(/* webpackChunkName: "ResetPassword" */ "./ResetPasswordView"),
);

export const SetNewPasswordView = loadable(
  async () =>
    await import(
      /* webpackChunkName: "SetNewPassword" */ "./SetNewPasswordView"
    ),
);
