import { createContext } from "react";

export interface LocationCords {
  latitude: number;
  longitude: number;
}

export interface LocationState extends LocationCords {
  googlePlaceId: string;
  addressMainText: string;
  addressSecondaryText: string;
}

export interface ILocationContext {
  state?: LocationState;
  setState: (state?: LocationState) => void;
}

export const LocationContext = createContext<ILocationContext>({
  setState: () => undefined,
});
