import type { Reducer } from "react";
import { useReducer } from "react";

import type { CartAction, CartState, CartStoreIdentifier } from "./types";

export type CartReducer = Reducer<CartState, CartAction>;

const areStoresEqual = (
  store1: CartStoreIdentifier,
  store2: CartStoreIdentifier,
): boolean => store1.id === store2.id;

const reducer: CartReducer = (state, action) => {
  switch (action.type) {
    case "ADD_ITEM":
      return {
        store: action.payload.store,
        items:
          state.store !== undefined &&
          state.items !== undefined &&
          areStoresEqual(state.store, action.payload.store)
            ? [...state.items, action.payload.item]
            : [action.payload.item],
        updatedAt: new Date(),
      };

    case "REMOVE_ITEM": {
      const filteredItems = state.items?.filter(
        (item) => item.id !== action.payload,
      );

      return filteredItems !== undefined && filteredItems.length > 0
        ? {
            store: state.store,
            items: filteredItems,
            updatedAt: new Date(),
          }
        : {
            store: undefined,
            items: undefined,
            updatedAt: new Date(),
          };
    }

    case "UPDATE_ITEM":
      return {
        store: state.store,
        items: state.items?.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              quantity: action.payload.quantity,
            };
          }
          return item;
        }),
        updatedAt: new Date(),
      };

    case "CLEAR_CART":
      return {};

    case "SET_CART":
      return action.payload;

    default:
      return state;
  }
};

export const useCartReducer = (initialState: CartState) =>
  useReducer<CartReducer>(reducer, initialState);
