import { Path } from "react-native-svg";
import { createIcon } from "native-base";

export default createIcon({
  viewBox: "0 0 14 12",
  path: [
    <Path
      key="1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 11.4999C12.1391 11.519 13.9726 9.35132 13.9992 6.05047C14.0257 2.74962 13.0981 0.522333 10 0.5C8.0432 0.485894 7.29976 1.09268 6.81885 2.70756C6.53993 1.08721 5.95671 0.514033 3.99991 0.499927C0.90183 0.477593 0.0270047 2.64871 0.000474234 5.94956C-0.031842 9.97026 1.59029 11.4826 3.99991 11.4999C5.78774 11.5128 6.36518 10.7444 6.72827 8.92852C7.25216 10.9767 7.77521 11.4875 9.5 11.4999Z"
      fill="white"
    />,
    <Path
      key="2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.09141 11.4015C5.54689 11.7801 4.88959 11.9653 4.14508 11.96C2.78817 11.9503 1.67918 11.3742 0.961607 10.2674C0.281503 9.21849 -0.0141298 7.74873 0.000517628 5.95022C0.0122769 4.50634 0.170323 3.05657 0.762381 1.94825C1.06725 1.37755 1.49905 0.874293 2.10303 0.519919C2.70869 0.164558 3.4272 -0.00565684 4.24248 0.000143306C5.05523 0.00592541 5.76071 0.185636 6.3318 0.575673C6.58111 0.745942 6.78816 0.944932 6.9603 1.16326C7.17571 0.933883 7.42571 0.729752 7.7153 0.559844C8.32096 0.204484 9.03947 0.0342689 9.85475 0.040069C10.67 0.0458692 11.3857 0.226287 11.9855 0.590224C12.5836 0.95315 13.0072 1.46249 13.3027 2.03746C13.8767 3.15408 14.0111 4.60593 13.9993 6.04981C13.9871 7.54321 13.7157 8.98368 13.0708 10.0798C12.4025 11.2156 11.3019 12.0109 9.75735 11.9999C8.55529 11.9913 7.54779 11.5382 6.83462 10.6655C6.63332 10.9437 6.3889 11.1947 6.09141 11.4015ZM9.76409 11.1719C12.0692 11.1883 13.0902 8.88883 13.1134 6.04351C13.1366 3.19819 12.554 0.887325 9.84801 0.868074C9.19563 0.863433 8.66448 0.993726 8.23371 1.23403C10.0085 2.20094 10.3297 3.27037 10.3173 4.80005C10.3063 6.14891 9.23299 6.66586 8.69771 6.75573C9.23147 6.8532 10.2965 7.3479 10.2868 8.54689C10.2794 9.45728 9.69337 10.4572 9.19044 11.1166C9.44821 11.1552 9.68028 11.1695 9.76409 11.1719ZM6.31623 9.85363C5.891 10.6556 5.21284 11.1395 4.15182 11.132C4.05524 11.1278 3.80529 11.1109 3.57812 11.0766C4.08105 10.4172 4.66705 9.41735 4.67446 8.50696C4.68423 7.30797 3.61915 6.81328 3.0854 6.7158C3.62068 6.62593 4.69399 6.10898 4.70498 4.76012C4.71743 3.23045 4.39616 2.16102 2.62142 1.19412C3.0522 0.953804 3.58335 0.823507 4.23574 0.828148C5.35742 0.836128 6.02439 1.23723 6.41924 1.90682C6.40408 1.93378 6.38922 1.9609 6.37465 1.98817C5.78259 3.09649 5.62455 4.54627 5.61279 5.99015C5.60014 7.54269 5.81871 8.85026 6.31623 9.85363Z"
      fill="black"
    />,
  ],
});
