import { type ReactNode } from "react";

import AuthProvider from "./auth/AuthProvider";
import { CannaboxThemeProvider } from "./cannabox-theme";
import CartProvider from "./cart/CartProvider";
import { FirebaseProvider } from "./firebase";
import { LocationProvider } from "./location";
import NavigationProvider from "./navigation/NavigationProvider";
import { ReactQueryProvider } from "./react-query";

export interface ServicesProviderProps {
  children: ReactNode;
}

export default function ServicesProvider({ children }: ServicesProviderProps) {
  return (
    <FirebaseProvider>
      <ReactQueryProvider>
        <CannaboxThemeProvider>
          <LocationProvider>
            <AuthProvider>
              <CartProvider>
                <NavigationProvider>{children}</NavigationProvider>
              </CartProvider>
            </AuthProvider>
          </LocationProvider>
        </CannaboxThemeProvider>
      </ReactQueryProvider>
    </FirebaseProvider>
  );
}
