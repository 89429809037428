import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

import type {
  ResetPasswordBodyParams,
  ResetPasswordSuccessResponse,
} from "@cannabox/api-types";

import type { ApiError } from "../errors";
import { fetchApi } from "../fetchers";

export type ResetPasswordMutationOptions = UseMutationOptions<
  ResetPasswordSuccessResponse,
  ApiError,
  ResetPasswordBodyParams
>;

const mutation = async (body: ResetPasswordBodyParams) =>
  await fetchApi<ResetPasswordSuccessResponse, ResetPasswordBodyParams>({
    url: "/auth/reset-password",
    method: "POST",
    body,
  });

export const useResetPasswordMutation = (
  options: ResetPasswordMutationOptions,
) =>
  useMutation<ResetPasswordSuccessResponse, ApiError, ResetPasswordBodyParams>(
    mutation,
    { ...options, mutationKey: ["reset-password"] },
  );
