export type CannaboxEnvType = "local" | "development" | "production";

export const cannaboxEnv: CannaboxEnvType =
  (process.env.CANNABOX_ENV as CannaboxEnvType | undefined) ?? "local";

export const apiUrl: string =
  process.env.API_URL ?? "http://localhost:3000/api";

export const appUrl: string = process.env.APP_URL ?? "http://localhost:3000";

export const googleMapsApiKey: string = process.env.GOOGLE_MAPS_API_KEY ?? "";
