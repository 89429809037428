import loadable from "@loadable/component";

export const StoreGalleryView = loadable(
  async () =>
    await import(/* webpackChunkName: "StoreGallery" */ "./StoreGalleryView"),
);
export const StoreReviewGalleryView = loadable(
  async () =>
    await import(
      /* webpackChunkName: "StoreReviewGallery" */ "./StoreReviewGalleryView"
    ),
);
