import { type IStoreOpenHoursRecord } from "@cannabox/api-types";

/**
 * Converts given minutes to milliseconds
 * @param minutes - The minutes to convert
 * @returns The minutes in milliseconds
 */
export function minutesToMilliseconds(minutes: number): number {
  return minutes * 60 * 1000;
}

/**
 * Converts given hours to milliseconds
 * @param hours - The hours to convert
 * @returns The hours in milliseconds
 */
export function hoursToMilliseconds(hours: number): number {
  return minutesToMilliseconds(hours * 60);
}

/**
 *  Extracts the time groups from a time string in the format "HH:MM AM/PM", "HH AM/PM" or "HH:MM", "HH".
 * @param time The time to extract
 * @returns An array of the extracted time groups
 */
export function extractTimeGroup(time: string): string[] {
  const regex = /^(0?[0-9]|1[0-9]|2[0-3])(?::(\d{2}))?\s*([ap]m)?$/i;
  const matches = regex.exec(time);

  if (!matches) {
    throw new Error(
      "Please use NUMBER, NUMBER:NUMBER or NUMBER AM/PM, NUMBER:NUMBER AM/PM format.",
    );
  }

  const [, h, m, t] = matches;

  return [h, m, t];
}

/**
 * Parses a time string in the format "HH:MM AM/PM" or "HH AM/PM" and returns the time in milliseconds.
 *
 * @param time - The time to parse
 * @return The time in milliseconds
 */
export function parseTime(time: string): number {
  const [h, m, t] = extractTimeGroup(time);

  const isAM = t !== "PM" && t !== "pm";

  let hours = isAM ? 0 : 12;

  if (h !== "12") {
    hours = isAM ? parseInt(h) : parseInt(h) + 12;
  }

  const minutes = m ? parseInt(m) : 0;

  return hoursToMilliseconds(hours) + minutesToMilliseconds(minutes);
}

/**
 * Extracts the open and close time from the given openHours.
 * @param schedule - The opening and closing hours as string.
 * @returns   - Returns an object with the open and close time in milliseconds.
 */
export function parseSchedule(schedule: string) {
  const [openTimeString, closeTimeString] = schedule
    .split(/[-–]/)
    .map((s) => s.trim());

  const openTime = parseTime(openTimeString);
  const closeTime = parseTime(closeTimeString);

  return { openTime, closeTime };
}

/**
 * Converts the given schedule string to opening and closing dates as numbers.
 * @param schedule  - The schedule string to convert.
 * @param timestamp   - The timestamp which is used as a base for the opening and closing dates.
 * @returns   - Returns an object with the opening and closing dates as numbers.
 */
export function convertScheduleStringToOpeningAndClosingDates(
  schedule: string,
  time: Date,
) {
  if (schedule.toLowerCase() === "closed") return null;

  const { openTime, closeTime } = parseSchedule(schedule);

  const beginningOfGivenDay = new Date(time).setHours(0, 0, 0);
  const openingDate = new Date(beginningOfGivenDay + openTime);
  const closingDate = new Date(beginningOfGivenDay + closeTime);

  return { openingDate, closingDate };
}

/**
 *  Returns the current day open hours from the given openHours.
 * @param openHours   - The opening and closing hours for the store.
 * @param time  - The time to check whether is store open.
 * @returns   - Returns the current day open hours.
 */
export function getOpenHoursForGivenDay(
  openHours: IStoreOpenHoursRecord[],
  time: Date,
) {
  const dayKey = time
    .toLocaleString("en-US", { weekday: "long" })
    .toLocaleLowerCase();

  const givenDay = openHours.find(({ day }) => day === dayKey);

  return givenDay?.schedule ?? "closed";
}

/**
 * Returns the opening and closing dates of the given day.
 * @param openHours   - The opening and closing hours for the store.
 * @param time  - The time to determine the opening and closing dates.
 * @returns   - Returns the opening and closing dates of the given day.
 */
export function getOpeningAndClosingDatesOfGivenDay(
  openHours: IStoreOpenHoursRecord[],
  time: Date,
) {
  const givenDaySchedule = getOpenHoursForGivenDay(openHours, time);
  const givenDayOpeningAndClosingDates =
    convertScheduleStringToOpeningAndClosingDates(givenDaySchedule, time);

  if (!givenDayOpeningAndClosingDates) return null;

  const { openingDate, closingDate } = givenDayOpeningAndClosingDates;

  const closeTimeAdjustmentForGivenDay =
    openingDate >= closingDate ? hoursToMilliseconds(24) : 0;

  return {
    openingDate,
    closingDate: new Date(
      closingDate.getTime() + closeTimeAdjustmentForGivenDay,
    ),
  };
}

/**
 * Returns a boolean to indicate if given time is between open and close time of the relevant day of openHours.
 *
 * @param openHours - The opening and closing hours for the store.
 * @param currentTime - Time to check whether is store open.
 * @returns - Returns true if store is open at the given time, returns false otherwise.
 */
export function isTimeWithinOpenHours(
  openHours: IStoreOpenHoursRecord[],
  time: Date,
): boolean {
  const previousDayDate = new Date(time.getTime() - hoursToMilliseconds(24));

  const givenDayOpeningAndClosingDates = getOpeningAndClosingDatesOfGivenDay(
    openHours,
    time,
  );
  const previousDayOpeningAndClosingDates = getOpeningAndClosingDatesOfGivenDay(
    openHours,
    previousDayDate,
  );

  const isTimeWithinGivenDay =
    givenDayOpeningAndClosingDates !== null &&
    time >= givenDayOpeningAndClosingDates.openingDate &&
    time <= givenDayOpeningAndClosingDates.closingDate;

  const isTimeWithinPreviousDay =
    previousDayOpeningAndClosingDates !== null &&
    time >= previousDayOpeningAndClosingDates.openingDate &&
    time <= previousDayOpeningAndClosingDates.closingDate;

  return isTimeWithinGivenDay || isTimeWithinPreviousDay;
}
