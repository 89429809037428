import { type ReactNode } from "react";

import { CartContext, initialCartState } from "./CartContext";
import { useCartReducer } from "./state";
import { useCartAsyncStorageSynchronizer } from "./useCartAsyncStorageSynchronizer";

export interface CartProviderProps {
  children: ReactNode;
}

export default function CartProvider({ children }: CartProviderProps) {
  const [state, dispatch] = useCartReducer(initialCartState);

  const isLoading = useCartAsyncStorageSynchronizer(state, dispatch);

  return (
    <CartContext.Provider
      value={{
        ...state,
        dispatch,
        isLoading,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
