import { useCallback, useContext } from "react";

import FirebaseContext from "./FirebaseContext";

export const useAnalytics = () => {
  const { analytics } = useContext(FirebaseContext);

  const logEvent = useCallback(
    (name: string, params?: Record<string, unknown>) => {
      const doLog = async () => {
        const { logEvent: logFirebaseAnalyticsEvent } = await import(
          "firebase/analytics"
        );

        if (analytics) {
          logFirebaseAnalyticsEvent(analytics, name, params);
        }
      };

      doLog().catch(console.error);
    },
    [analytics],
  );

  return { analytics, logEvent };
};
