import type {
  FlowerDataRecordCategoryType,
  PrerollDataRecordCategoryType,
  TinctureDataRecordCategoryType,
} from "@cannabox/api-types";

export const supportedFlowerCategories: Record<
  FlowerDataRecordCategoryType,
  string
> = {
  sativa: "Sativa",
  indica: "Indica",
  hybrid: "Hybrid",
};

export const supportedPrerollCategories: Record<
  PrerollDataRecordCategoryType,
  string
> = {
  sativa: "Sativa",
  indica: "Indica",
  hybrid: "Hybrid",
};

export const supportedTincturesCategories: Record<
  TinctureDataRecordCategoryType,
  string
> = {
  sativa: "Sativa",
  indica: "Indica",
  hybrid: "Hybrid",
};
