import { useEffect } from "react";
import { useNavigationState } from "@react-navigation/native";
import { Button, Text, VStack } from "native-base";

import { useRootNavigation } from "~root/navigation";
import { useLocation } from "~root/services/location";
import MapPinIcon from "./MapPinIcon";

export default function AddressHeaderTitle() {
  const navigationIndex = useNavigationState((state) => state.index);
  const navigation = useRootNavigation();
  const locationState = useLocation();

  const hasLocation = locationState !== undefined;
  const shouldShowIcon = navigationIndex === 0;

  useEffect(() => {
    if (shouldShowIcon) {
      navigation.setOptions({
        headerLeft: () =>
          hasLocation ? (
            <MapPinIcon color="white" ml="4" />
          ) : (
            <MapPinIcon color="#FFCB5C" ml="4" />
          ),
      });
    }
  }, [navigation, shouldShowIcon, hasLocation]);

  if (locationState === undefined) {
    return (
      <Button
        variant="link"
        colorScheme="white"
        px="0"
        size="sm"
        onPress={() => {
          navigation.navigate("AddressPicker");
        }}
      >
        Click to choose the location first!
      </Button>
    );
  }

  return (
    <Button
      variant="ghost"
      colorScheme="gray"
      px="0"
      onPress={() => {
        navigation.navigate("AddressPicker");
      }}
    >
      <VStack>
        <Text
          color="white"
          fontSize="md"
          fontWeight="medium"
          noOfLines={1}
          maxW="220px"
        >
          {locationState.addressMainText}
        </Text>
        <Text color="white" fontSize="2xs" noOfLines={1} maxW="260px">
          {locationState.addressSecondaryText}
        </Text>
      </VStack>
    </Button>
  );
}
