import { type IInputProps } from "native-base";

const filledVariant = ({ colorScheme }: IInputProps): IInputProps => {
  switch (colorScheme) {
    case "dark":
      return {
        bg: "gray.700",
        borderColor: "gray.700",
        color: "white",
      };

    case "white":
      return {
        bg: "white",
        borderColor: "white",
      };
    case "gray":
    default:
      return {
        bg: "gray.50",
      };
  }
};

export default {
  defaultProps: {
    borderRadius: "2xl",
    variant: "filled",
  },
  sizes: {
    md: {
      fontSize: "md",
      paddingTop: "11px",
      paddingBottom: "10px",
    },
    lg: {
      fontSize: "lg",
      paddingTop: "13px",
      paddingBottom: "13px",
    },
  },
  variants: {
    filled: filledVariant,
  },
};
