import {
  type IGetOneStoreRecord,
  type IGetOneStoreUrlParams,
} from "@cannabox/api-types";

import type { UseApiQueryOptions } from "../hooks/useApiQuery";
import useApiQuery from "../hooks/useApiQuery";

export type IUseStoreQueryOptions = IGetOneStoreUrlParams & {
  options?: UseApiQueryOptions;
};

const useStoreQuery = ({ city, urlSlug, options }: IUseStoreQueryOptions) =>
  useApiQuery<IGetOneStoreRecord>(`/stores/${city}/${urlSlug}`, {}, options);

export default useStoreQuery;
