import type {
  ReverseGeocodeQueryParams,
  ReverseGeocodeRecord,
} from "@cannabox/api-types";

import { fetchApiSingle } from "~root/services/api/fetchers";

const API_PATH = "/maps/reverse-geocode";

export const fetchCordsPlace = async (params: ReverseGeocodeQueryParams) => {
  return await fetchApiSingle<ReverseGeocodeRecord>(API_PATH, { ...params });
};
